import { define, html } from "hybrids";

export interface VUiText {
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "button" | "caption" | "";
  weight: "normal" | "semibold" | "bold" | "";
  color: string;
  selectable: boolean;
  inline: boolean;
  ellipsis: boolean;
  uppercase: boolean;
  xgutter: number;
  ygutter: number;
  center: boolean;
  justify: boolean;
}

export default define<VUiText>({
  tag: "v-ui-text",
  variant: "",
  weight: "",
  color: "text-primary",
  selectable: false,
  inline: false,
  ellipsis: false,
  uppercase: false,
  xgutter: 0,
  ygutter: 0,
  center: false,
  justify: false,
  render: ({ color, xgutter, ygutter }) => html`<slot></slot>`.css`
    :host {
      display: block;
      font-family: Averta, sans-serif;
      font-size: var(--v-ui-font-size-default);
      line-height: var(--v-ui-line-height);
      color: var(--v-ui-color-${color});
      padding: calc(var(--v-ui-text-gutter) * ${ ygutter }) calc(var(--v-ui-layout-spacing) * ${ xgutter });
    }

    :host([inline]) { display: inline; margin: 0; }
    :host([ellipsis]) { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

    :host ::slotted(:not(v-ui-text)) {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      margin: 0;
    }

    :host([variant="h1"]) { font-weight: bold; font-size: var(--v-ui-font-size-h1); }
    :host([variant="h2"]) { font-weight: 600; font-size: var(--v-ui-font-size-h2); }
    :host([variant="h3"]) { font-weight: 600; font-size: var(--v-ui-font-size-h3); }
    :host([variant="h4"]) { font-weight: 600; font-size: var(--v-ui-font-size-h4); }
    :host([variant="h5"]) { font-weight: 600; font-size: var(--v-ui-font-size-h5); }
    :host([variant="button"]) { font-weight: normal; font-size: var(--v-ui-font-size-button); }
    :host([variant="caption"]) { font-weight: normal; font-size: var(--v-ui-font-size-caption); }
    :host([variant="input"]) { font-weight: normal; font-size: var(--v-ui-font-size-input); }

    :host([weight="normal"]) { font-weight: 400; }
    :host([weight="semibold"]) { font-weight: 600; }
    :host([weight="bold"]) { font-weight: 700; }
    :host([weight="black"]) { font-weight: 900; }

    :host([selectable]) { user-select: text; -webkit-user-select: text; }
    :host([uppercase]) { text-transform: uppercase; }

    :host([ygutter]:first-child) { padding-top: 0; }
    :host([ygutter]:last-child) { padding-bottom: 0; }

    :host([center]) { text-align: center }
    :host([justify]) { text-align: justify; text-justify: inter-word; }
  `,
});
