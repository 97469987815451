import { define, html } from "hybrids";

interface VUiDropDownContent {
  title: string;
  showContent: boolean;
}

export default define<VUiDropDownContent>({
  tag: "v-ui-drop-down-content",
  title: "",
  showContent: false,
  render: ({ title, showContent }) => html`
    <div 
      class="title"
      onclick="${html.set('showContent', !showContent)}"
    >
      <v-ui-text weight="bold">${title}</v-ui-text>
      <v-ui-button
        type="transparent"
        icon="${showContent ? "chevronup" : "chevrondown"}" 
        iconcolor="text-primary"
        iconsize="medium"
      ></v-ui-button>
    </div>
    <div class="content">
      <v-ui-text color="text-secondary">
        <slot></slot>
      </v-ui-text>
    </div>
  `.css`
    :host {
      display: block;
      width: 100%;
      max-width: 652px;
      border-top: 1px solid #E9E9E9;
      padding: 20px 0px;
      margin: auto;
      overflow: hidden;
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
    .content {
      opacity: 0;
      max-height: 0;
      transition: opacity 0.5s, max-height 0.5s, padding-top 0.5s;
      overflow: scroll;
      /* Hide scrollbar for IE, Edge */
      -ms-overflow-style: none;
      /* Hide scrollbar for Firefox */
      scrollbar-width: none;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .content::-webkit-scrollbar {
      display: none;
    }
    
    :host([show-content]) .content {
      opacity: 1;
      max-height: 500px;
      padding-top: 20px;
    }
  `,
});
