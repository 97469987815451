import { router } from "hybrids";
import "~/utils/analytics";

import "./styles.css";

import "./elements/Alert";
import "./elements/Avatar";
import "./elements/ButtonGroup";
import "./elements/Divider";
import "./elements/Button";
import "./elements/Flexbox";
import "./elements/FormError";
import "./elements/FormField";
import "./elements/Icon";
import "./elements/Input";
import "./elements/Intersect";
import "./elements/Link";
import "./elements/Poster";
import "./elements/Radio";
import "./elements/Select";
import "./elements/Text";
import "./elements/DropDownContent";

// Turn on router debug mode in development
if (import.meta.env.DEV) router.debug();

// Show content when DOM is ready
document.addEventListener(
  "DOMContentLoaded",
  () => {
    document.body.classList.add("ready");
  },
  { once: true }
);
