import { html, define, store } from "hybrids";

interface VUiFormField {
  label: string;
  model: any;
  property: string;
  placeholder: string;
  type: string;
  name: string;
  description: string;
  error: string;
  required: boolean;
}

export default define<VUiFormField>({
  tag: "v-ui-form-field",
  label: "",
  model: undefined,
  property: "",
  placeholder: "",
  type: "",
  name: "",
  description: "",
  error: ({ model, property }) => model && store.error(model, property),
  required: false,
  render: ({ label, description, error, required }) => html`
    <v-ui-text id="label" weight="semibold" variant="input">
      <slot name="label">${label}</slot>${required &&
      html`<v-ui-text color="alert-danger" inline>*</v-ui-text>`}
    </v-ui-text>
    ${description &&
    html`<v-ui-text color="text-secondary">${description}</v-ui-text>`}
    <v-ui-input class="${{ error }}">
      <slot></slot>
    </v-ui-input>
    ${error &&
    html`<v-ui-text id="error" variant="caption" color="alert-danger"
      >${error}</v-ui-text
    >`}
  `.css`
    :host { display: block }

    #label {
      margin-bottom: 4px;
    }

    v-ui-input.error {
      border-color: var(--v-ui-color-alert-danger);
    }

    #error {
      margin-top: 8px;
    }
  `,
  content: (host) => {
    if (host.model) {
      const { model, type, name, label, property, placeholder } = host;

      return html`
        <input
          type="${type || "text"}"
          name="${name || label}"
          value=${model[property]}
          oninput="${html.set(model, property)}"
          placeholder="${placeholder}"
        />
      `;
    }

    return () => {};
  },
});
