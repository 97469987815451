import { define, html } from "hybrids";
import { VUiIcon } from "./Icon";

export interface VUiButton extends HTMLElement {
  href: string;
  target: string;
  name: string;
  icon: VUiIcon["name"] | undefined;
  iconsize: "small" | "medium" | "large" | "";
  iconcolor: string;
  variant: "default" | "outline" | "icon" | "inline";
  color: string;
  type: "round" | "transparent" | "cta" | "long" | "";
  active: boolean;
  disabled: boolean;
  pending: boolean;
  dot: boolean;
  uppercase: boolean;
  weight: "normal" | "semibold";
  direction: "row" | "column";
  justify: "center" | "left" | "right";
}

function content({ icon, name, type, disabled, weight, color, iconsize, iconcolor }: Partial<VUiButton>) {
  let size = iconsize || (name || type === "round" ? "" : "large");
  let textColor = color === "white" ? "text-primary" : "button";

  return html`
    ${icon &&
    html`
      <v-ui-icon name="${icon}" size="${size}" color=${iconcolor}></v-ui-icon>`}
    ${name &&
    html`
      <v-ui-text weight="${weight}" variant="button" color="${textColor}">
        ${name}
      </v-ui-text>`}
    ${!icon &&
    !name &&
    html`
      <slot tabindex="${disabled ? -1 : undefined}"></slot>`}
  `;
}

export default define<VUiButton>({
  tag: "v-ui-button",
  href: "",
  target: "_self",
  name: "",
  icon: "",
  variant: "default",
  color: "secondary",
  type: "",
  active: false,
  disabled: false,
  pending: false,
  dot: false,
  uppercase: false,
  weight: "semibold",
  direction: "row",
  justify: "center",
  iconsize: "",
  iconcolor: "button",
  // @ts-ignore
  click: (host) => () => host.render().children[0].click(),
  render: ({
    href,
    target,
    name,
    type,
    variant,
    color,
    icon,
    active,
    disabled,
    pending,
    dot,
    title,
    uppercase,
    weight,
    direction,
    justify,
    iconsize,
    iconcolor
  }) => (href
      ? html`<a
        title="${title || name}"
        href="${disabled ? "" : href}"
        target=${target}
        rel=${target === "_blank" ? "noreferer noopener" : ""}
        id="button"
        class="${{
          [variant]: variant,
          [type]: type,
          active,
          disabled,
          pending,
          uppercase,
        }}"
        tabindex="${disabled ? -1 : undefined}"
      >
        ${content({ icon, name, type, disabled, weight, color, iconsize, iconcolor })}
      </a>
      ${dot && html`
        <div id="dot"></div>`} `
      : html`
        <button
          id="button"
          title="${title || name}"
          class="${{
            [variant]: variant,
            [type]: type,
            active,
            pending,
            uppercase,
          }}"
          disabled="${disabled || pending}"
        >
          ${content({ icon, name, type, disabled, weight, color, iconsize, iconcolor })}
          ${dot && html`
            <div id="dot"></div>`}
        </button>`
  ).css`
    :host {
      position: relative;
      display: grid;
      grid: auto / minmax(max-content, 1fr);
      box-sizing: border-box;
      max-height: 100%;
    }

    :host([hidden]) {
      display: none;
    }

    :host([variant="default"]), :host([variant="outline"]) {
      height: 40px;
      --v-ui-icon-color: white;
    }

    :host([variant="inline"]) { display: inline-flex; }
    :host([variant="icon"]) { min-width: 42px; }
    :host([type="round"]) { min-width: 0; height: auto; }
    :host([type="cta"]) { height: 46px; }
    :host([type="long"]) { width: 100%;  max-width: 230px; }

    :host([disabled]), :host([pending]) { pointer-events: none; }

    #button {
      box-sizing: border-box;
      display: flex;
      flex: 1 0 auto;
      flex-direction: ${direction};
      align-items: center;
      justify-content: ${justify};
      text-decoration: none;
      margin: 0;
      padding: 0;
      background: var(--v-ui-color-${color});
      font-family: Averta, sans-serif;
      font-size: var(--v-ui-font-size-button);
      font-weight: 600;
      color: var(--v-ui-color-button);
      cursor: pointer;
      border: none;
      border-radius: 8px;
      overflow: hidden;
      white-space: nowrap;
      touch-action: manipulation;
      transition: opacity 200ms;
      will-change: opacity;
    }

    #button:active:not(.disabled):not([disabled]) {
      opacity: 0.8;
    }

    @media (hover: hover) and (pointer: fine) {
      #button:hover:not(.disabled):not([disabled]) {
        opacity: 0.8;
      }

      #button:active:not(.disabled):not([disabled]) {
        opacity: 0.6;
      }
    }

    #button:focus:not(:focus-visible) {
      outline: none;
    }

    #button.disabled, #button[disabled] {
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
    }

    #button ::slotted(button) {
      display: block;
      border: 0;
      background: none;
      width: 100%;
      height: 100%;
      cursor: pointer;
      color: inherit;
      font-family: inherit;
      font-size: inherit;
    }

    #button.default v-ui-text {
      margin: 0 16px;
    }

    #button.default v-ui-text:not(:first-child) {
      margin: 0 16px 0 2px;
    }

    #button.default v-ui-icon {
      margin: 0 6px;
    }

    #button.default v-ui-icon:not(:last-child) {
      margin-left: 16px;
    }

    #button.default ::slotted(button) {
      color: white;
      font-family: inherit !important;
      font-size: var(--v-ui-font-size-button) !important;
      font-weight: 600 !important;
    }

    @keyframes pulse {
      from { opacity: 0.5; }
      to { opacity: 0.8; }
    }

    #button.pending {
      animation: pulse 1s infinite alternate;
    }

    #button.outline {
      border: 1px solid var(--v-ui-button-outline-border, rgba(0, 0, 0, 0.1));
    }

    #button.outline:not(.default) {
      background: var(--v-ui-button-outline-background);
    }

    #button.icon {
      flex-flow: column;
      background: none;
      grid: 1fr auto / auto;
      grid-gap: 0px;
      padding: 0;
      opacity: 0.6;
      overflow: visible;
      --v-ui-color-button: var(--v-ui-color-text-primary);
    }

    #button.icon:active:not(.disabled),
    #button.icon:active:not([disabled]) {
      opacity: 0.5;
    }

    #button.icon.active {
      opacity: 1;
      --v-ui-color-button: var(--v-ui-color-action);
      --v-ui-color-icon: var(--v-ui-color-action);
    }

    #button.icon v-ui-text {
      margin: 0;
      font-size: var(--v-ui-font-size-button-icon);
      font-weight: normal;
    }

    #button.icon.active v-ui-text {
      font-weight: 600;
    }

    #button.icon v-ui-icon {
      margin: 0;
      width: 28px;
      height: 28px;
    }

    #button.round {
      padding: 0;
      border-radius: 100%;
    }

    #button.round v-ui-icon {
      margin: 0;
      width: 75%;
      height: 75%;
    }

    #button.inline, #button.inline v-ui-text {
      margin: 0;
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      color: inherit;
      border-radius: 0px;
      background: none;
      overflow: visible;
    }

    #button.underline v-ui-text {
      text-decoration: underline;
    }

    #button.transparent {
      background: none;
      --v-ui-color-button: var(--v-ui-color-${color});
      --v-ui-icon-color: var(--v-ui-color-${iconcolor});
    }

    #button.uppercase {
      text-transform: uppercase;
    }

    #button.cta {
      padding: 0px 12px;
      box-shadow: 2px 4px 11px rgba(24, 34, 43, 0.11);
      min-width: 160px;
    }

    #dot {
      position: absolute;
      top: var(--v-ui-button-dot-top, 0px);
      right: var(--v-ui-button-dot-right, 5px);
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background: var(--v-ui-button-dot-color, #D60B52);
      border: var(--v-ui-button-dot-border, none);
    }
  `,
});
