import { define, html } from "hybrids";

import add from "../assets/icon-add.svg?raw";
import arrowdown from "../assets/icon-arrowdown.svg?raw";
import arrowup from "../assets/icon-arrowup.svg?raw";
import applause from "../assets/icon-applause.svg?raw";
import back from "../assets/icon-back.svg?raw";
import calendar from "../assets/icon-calendar.svg?raw";
import calendardays from "../assets/icon-calendar-days.svg?raw";
import card from "../assets/icon-card.svg?raw";
import chat from "../assets/icon-chat.svg?raw";
import check from "../assets/icon-check.svg?raw";
import close from "../assets/icon-close.svg?raw";
import crown from "../assets/icon-crown.svg?raw";
import discord from "../assets/icon-discord.svg?raw";
import donate from "../assets/icon-donate.svg?raw";
import drums from "../assets/icon-drums.svg?raw";
import edit from "../assets/icon-edit.svg?raw";
import error from "../assets/icon-error.svg?raw";
import facebook from "../assets/icon-facebook.svg?raw";
import fire from "../assets/icon-fire-red.svg?raw";
import gear from "../assets/icon-gear.svg?raw";
import google from "../assets/icon-google.svg?raw";
import heart from "../assets/icon-heart.svg?raw";
import heartfill from "../assets/icon-heartfill.svg?raw";
import instagram from "../assets/icon-instagram.svg?raw";
import link from "../assets/icon-link.svg?raw";
import linkedin from "../assets/icon-linkedin.svg?raw";
import live from "../assets/icon-live.svg?raw";
import logo from "../assets/icon-logo.svg?raw";
import logofull from "../assets/icon-logofull.svg?raw";
import logofullwhite from "../assets/icon-logofull-white.svg?raw";
import mail from "../assets/icon-mail.svg?raw";
import map from "../assets/icon-map.svg?raw";
import notification from "../assets/icon-notification.svg?raw";
import order from "../assets/icon-order.svg?raw";
import paypal from "../assets/icon-paypal.svg?raw";
import paypalfull from "../assets/icon-paypalfull.svg?raw";
import people from "../assets/icon-people.svg?raw";
import play from "../assets/icon-play.svg?raw";
import plus from "../assets/icon-plus.svg?raw";
import rightfrombracket from "../assets/icon-right-from-bracket.svg?raw";
import screwdriverwrench from "../assets/icon-screwdriver-wrench.svg?raw";
import search from "../assets/icon-search.svg?raw";
import send from "../assets/icon-send.svg?raw";
import spinner from "../assets/icon-spinner.svg?raw";
import share from "../assets/icon-share.svg?raw";
import stats from "../assets/icon-stats.svg?raw";
import ticket from "../assets/icon-ticket.svg?raw";
import telegram from "../assets/icon-telegram.svg?raw";
import time from "../assets/icon-time.svg?raw";
import twitter from "../assets/icon-twitter.svg?raw";
import user from "../assets/icon-user.svg?raw";
import userplus from "../assets/icon-userplus.svg?raw";
import users from "../assets/icon-users.svg?raw";
import usertwo from "../assets/icon-usertwo.svg?raw";
import whatsapp from "../assets/icon-whatsapp.svg?raw";
import info from "../assets/icon-info.svg?raw";
import appstore from "../assets/icon-appstore.svg?raw";
import appstorelogo from "../assets/icon-appstore-logo.svg?raw";
import playstore from "../assets/icon-playstore.svg?raw";
import playstorelogo from "../assets/icon-playstore-logo.svg?raw";
import chevrondown from "../assets/icon-chevron-down.svg?raw";
import chevronup from "../assets/icon-chevron-up.svg?raw";
import fireyellow from "../assets/icon-fire-yellow.svg?raw";
import fireorange from "../assets/icon-fire-orange.svg?raw";
import firered from "../assets/icon-fire-red.svg?raw";

const icons = {
  add,
  arrowdown,
  arrowup,
  applause,
  back,
  calendar,
  calendardays,
  card,
  chat,
  check,
  close,
  crown,
  discord,
  donate,
  drums,
  edit,
  error,
  facebook,
  fire,
  gear,
  google,
  heart,
  heartfill,
  instagram,
  link,
  linkedin,
  live,
  logo,
  logofull,
  logofullwhite,
  mail,
  map,
  notification,
  order,
  paypal,
  paypalfull,
  people,
  play,
  plus,
  rightfrombracket,
  screwdriverwrench,
  search,
  send,
  spinner,
  share,
  stats,
  ticket,
  telegram,
  time,
  twitter,
  user,
  userplus,
  users,
  usertwo,
  whatsapp,
  appstore,
  appstorelogo,
  playstore,
  playstorelogo,
  info,
  chevrondown,
  chevronup,
  fireyellow,
  fireorange,
  firered,
};

export interface VUiIcon {
  name: keyof typeof icons | "";
  color: string;
  type: "stroke" | "fill";
  size: "small" | "medium" | "large" | "xl" | "";
}

const strokeIcons = ["back", "close", "time", "playstore"];

export default define<VUiIcon>({
  tag: "v-ui-icon",
  name: "",
  color: "icon",
  size: "",
  type: ({ name }) => (strokeIcons.includes(name) ? "stroke" : "fill"),
  render: ({ name, color, type, size }) => html`
    <div
      class="${{
        multi: !color,
        [type]: type,
        [size]: size,
      }}"
      innerHTML="${name ? icons[name] || "" : ""}"
    ></div>
  `.css`
    :host {
      display: inline-flex;
      vertical-align: top;
    }

    div {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: var(--v-ui-icon-width, auto);
      height: var(--v-ui-icon-height, auto);
    }

    div.fill:not(.multi) svg * {
      fill: var(--v-ui-color-${color}, var(--v-ui-icon-color, gray));
    }

    div.stroke:not(.multi) svg * {
      stroke: var(--v-ui-color-${color}, var(--v-ui-icon-color, gray));
    }

    div.small svg {
      min-width: 16px;
      height: 16px;
    }

    div.medium svg {
      min-width: 24px;
      height: 24px;
    }

    div.large svg {
      min-width: 30px;
      height: 30px;
    }
    
    div.xl svg {
      min-width: 40px;
      height: 45px;
    }
  `,
});
