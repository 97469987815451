export function sendPageView(url: URL) {
  if ("ga" in window && typeof ga.getAll === "function") {
    const name = ga.getAll()[0].get("name");
    ga(`${name}.set`, "page", url.href.replace(window.location.origin, ""));
    ga(`${name}.send`, "pageview");
  }
}

function logPageView(event: Event) {
  const { url } = (event as CustomEvent).detail;
  sendPageView(url);
}

if ("gtag" in window) {
  document.addEventListener("navigate", logPageView);
}

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    document.removeEventListener("navigate", logPageView);
  });
}
