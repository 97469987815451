import { define, html } from "hybrids";

export interface VUiInput {
  prefixText: string;
}

export default define<VUiInput>({
  tag: "v-ui-input",
  prefixText: "",
  render: ({ prefixText }) => html`
    <slot name="header">
      ${!!prefixText &&
      html`<v-ui-text color="text-secondary">${prefixText}</v-ui-text>`}
    </slot>
    <div id="input"><slot></slot></div>
    <slot name="footer"></slot>
  `.css`
    :host {
      box-sizing: border-box;
      display: flex;
      border: 1px solid var(--v-ui-input-border-color);
      background: var(--v-ui-input-background);
      border-radius: 8px;
      padding: 9px 12px;
      min-height: 40px;
    }
    
    slot[name="header"] v-ui-text {
      margin-right: 8px;
    }

    #input {
      flex-grow: 1;
      display: grid;
      align-items: center;
    }

    #input ::slotted(*) {
      appearance: none;
      -webkit-appearance: none;
      background: none;
      border: none;
      font-family: inherit;
      font-size: var(--v-ui-font-size-input) !important;
      line-height: var(--v-ui-line-height) !important;
      color: var(--v-ui-input-color);
      margin: 0;
      padding: 0;
    }

    #input ::slotted(*:focus) {
      border: none;
      outline: none;
    }

    #input ::slotted([disabled]) {
      color: var(--v-ui-color-text-secondary);
    }
  `,
});
