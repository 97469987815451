import { define, html } from "hybrids";

export interface VUiAvatar {
  src: string;
  alt: string;
  error: boolean;
}

function setError(host: VUiAvatar) {
  host.error = true;
}

export default define<VUiAvatar>({
  tag: "v-ui-avatar",
  src: "",
  alt: "",
  error: false,
  render: ({ src, alt }) => html`
    <div id="img">
      <img src="${src}" alt="${alt}" loading="lazy" onerror="${setError}" />
    </div>
    <slot></slot>
  `.css`
    :host {
      box-sizing: border-box;
      display: block;
      border-radius: 100%;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      overflow: hidden;
      border: 1px solid var(--v-ui-avatar-border-color, transparent);
      border-width: var(--v-ui-avatar-border-width, 0px);
      max-width: var(--v-ui-avatar-size, 48px);
      max-height: var(--v-ui-avatar-size, 48px);
      width: var(--v-ui-avatar-size, 100%);
      height: var(--v-ui-avatar-size, 100%);
    }

    #img {
      position: relative;
      width: 100%;
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }

    :host([error]) {
      background: var(--v-ui-color-background);
    }

    :host([error]) img { display: none; }
  `,
});
