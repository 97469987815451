import { define, html } from "hybrids";

interface VUiRadio {
  size: "small" | "";
}

export default define<VUiRadio>({
  tag: "v-ui-radio",
  size: "",
  render: () => html`<slot></slot>`.css`
    :host {
      display: flex;
      flex-flow: row;
      align-items: center;
      cursor: pointer;
    }
    :host ::slotted(input[type="radio"]) {
      cursor: pointer;
      appearance: none;
      margin: 0 12px 0 0;
      padding: 0;
      width: 24px;
      height: 24px;
      background: #FFFFFF;
      border-radius: 12px;
      border: 2px solid #D9DDE1;
    }

    :host ::slotted(input[type="radio"]:checked) {
      border: 8px solid #662483;
    }

    :host([size="small"]) ::slotted(input[type="radio"]) {
      width: 16px;
      height: 16px;
    }

    :host([size="small"]) ::slotted(input[type="radio"]:checked) {
      border-width: 4px;
    }
  `,
});
