import { define, html } from "hybrids";

interface VUiSelect {}

export default define<VUiSelect>({
  tag: "v-ui-select",
  render: () => html`
    <v-ui-input>
      <slot></slot>
      <v-ui-icon
        name="arrowdown"
        color="text-secondary"
        size="small"
      ></v-ui-icon>
    </v-ui-input>
  `.css`
    :host {
      display: block;
    }

    v-ui-input {
      position: relative;
      padding: 0;
    }

    v-ui-input ::slotted(*) {
      cursor: pointer;
      padding: 9px 28px 9px 14px;
    }

    v-ui-icon {
      display: flex;
      position: absolute;
      pointer-events: none;
      right: 8px;
      top: 50%;
      margin-top: -10px;
    }
  `,
});
