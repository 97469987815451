import { define, html } from "hybrids";

export interface VUiButtonGroup {
  flow: "row" | "column";
}

export default define<VUiButtonGroup>({
  tag: "v-ui-button-group",
  flow: "row",
  render: () => html`<slot></slot>`.css`
    :host {
      display: grid;
      grid-gap: 16px;
      grid-auto-rows: 40px;
      margin: 8px 0;
    }

    :host([flow="column"]) {
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
    }

    :host(:first-child) { margin-top: 0; }
    :host(:last-child) { margin-bottom: 0; }
  `,
});
