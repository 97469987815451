import { define, html } from "hybrids";

interface VUiPoster {
  src: string;
}

export default define<VUiPoster>({
  tag: "v-ui-poster",
  src: "",
  render: ({ src }) => html`
    <div>
      ${src && html`<img src="${src}" loading="lazy" />`}
      <slot></slot>
    </div>
  `.css`
    :host { 
      display: block; 
      background-color: var(--v-ui-color-background);
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      overflow: hidden;
      border-radius: 8px;
    }

    div {
      position: relative;
      padding-top: 56.25%;
    }

    img {
      position: absolute;
      top: 0;
      left :0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
});
