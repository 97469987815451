import { define, dispatch } from "hybrids";

interface VUiIntersect {
  visible: boolean;
}

export default define<VUiIntersect>({
  tag: "v-ui-intersect",
  visible: {
    value: false,
    connect: (host, key) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          host[key] = entry.isIntersecting;
        },
        { threshold: 1 }
      );

      observer.observe(host);

      return () => observer.disconnect();
    },
    observe: (host, value) => {
      if (value) dispatch(host, "visible");
    },
  },
});
