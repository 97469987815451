import { html, define } from "hybrids";

export default define({
  tag: "v-ui-divider",
  render: () => html`
    <div>
      <v-ui-text variant="caption" color="text-secondary">
        <slot></slot>
      </v-ui-text>
    </div>
  `.css`
    div {
      position: relative;
      display: grid;
      grid: auto / 1fr max-content 1fr;
      align-items: center;
      font-size: var(--v-ui-font-size-caption);
    }

    div:before {
      display: block;
      content: "";
      height: 1px;
      border-bottom: 1px solid var(--v-ui-color-background);
    }

    div:after {
      display: block;
      content: "";
      height: 1px;
      border-bottom: 1px solid var(--v-ui-color-background);
    }

    v-ui-text {
      padding: 0 16px;
      opacity: 0.7;
    }
  `,
});
