import { define, html } from "hybrids";

interface VUiAlert {
  type: "info" | "success" | "warning" | "danger";
}

export default define<VUiAlert>({
  tag: "v-ui-alert",
  type: "info",
  render: ({ type }) => html`
    <div id="background" class="layout"></div>
    <div id="border" class="layout"></div>
    <v-ui-text color="alert-${type}"><slot></slot></v-ui-text>
  `.css`
    :host { 
      display: block;
      position: relative;
      padding: calc(var(--v-ui-layout-spacing) * 0.75);
      margin: var(--v-ui-layout-spacing) 0;
      --v-ui-alert-color: var(--v-ui-color-alert-${type}, var(--v-ui-color-alert-info));
    }
    
    .layout {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    #background {
      background: var(--v-ui-alert-color);
      opacity: 0.05;
    }

    #border {
      border: 1px solid var(--v-ui-alert-color);
      opacity: 0.2;
    }

    v-ui-text {
      position: relative;
      --v-ui-color-link: var(--v-ui-alert-color);
    }

    v-ui-text ::slotted(a) {
      font-weight: 600;
    }
  `,
});
