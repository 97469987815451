import { define, html } from "hybrids";

export interface VUiFlexbox {
  flow: string;
  layout: string;
  grow: number;
  shrink: boolean;
  gap: "" | "small" | "medium" | "large";
}

export default define<VUiFlexbox>({
  tag: "v-ui-flexbox",
  flow: "column",
  layout: "",
  grow: 0,
  shrink: false,
  gap: "",
  render: () => html`<slot></slot>`.css`
    :host { display: flex; flex-direction: column; flex: 0 0 auto; max-width: 100%}
    :host([hidden]) { display: none }

    :host([flow^="row"]) { flex-direction: row }
    :host([flow^="row-reverse"]) { flex-direction: row-reverse }
    :host([flow^="column"]) { flex-direction: column }
    :host([flow^="column-reverse"]) { flex-direction: column-reverse }

    :host([flow~="wrap"]) { flex-wrap: wrap; }
    :host([flow~="nowrap"]) { flex-wrap: nowrap; }
    :host([flow~="inline"]) { display: inline-flex }
    :host([flow~="overflow"]) { overflow: auto; overscroll-behavior: contain; }

    :host([layout^="start"]) { justify-content: flex-start }
    :host([layout^="end"]) { justify-content: flex-end }
    :host([layout^="center"]) { justify-content: center }
    :host([layout^="stretch"]) { justify-content: stretch }
    :host([layout^="space-around"]) { justify-content: space-around }
    :host([layout^="space-between"]) { justify-content: space-between }

    :host([layout$="start"]) { align-items: flex-start }
    :host([layout$="end"]) { align-items: flex-end }
    :host([layout$="center"]) { align-items: center }
    :host([layout$="stretch"]) { align-items: stretch }

    :host(:not([grow="0"])) { flex: 1 0 auto; min-height: 0; min-width: 0; }

    :host([grow="1"]) { flex-grow: 1; }
    :host([grow="2"]) { flex-grow: 2; }
    :host([grow="3"]) { flex-grow: 3; }
    :host([grow="4"]) { flex-grow: 4; }
    :host([grow="5"]) { flex-grow: 5; }
    :host([grow="6"]) { flex-grow: 6; }
    :host([grow="7"]) { flex-grow: 7; }
    :host([grow="8"]) { flex-grow: 8; }
    :host([grow="9"]) { flex-grow: 9; }
    :host([grow="10"]) { flex-grow: 10; }

    :host([shrink]) { flex-shrink: 1; }

    :host([gap="small"]), :host([gap="small"]) ::slotted(*) { --v-ui-flexbox-gap-size: 0.25; }
    :host([gap="medium"]), :host([gap="medium"]) ::slotted(*) { --v-ui-flexbox-gap-size: 0.5; }
    :host([gap="large"]), :host([gap="large"]) ::slotted(*) { --v-ui-flexbox-gap-size: 1; }

    :host([flow^="column"][gap]) ::slotted(*) {
      margin: calc(var(--v-ui-flexbox-gap, var(--v-ui-layout-spacing)) * var(--v-ui-flexbox-gap-size)) 0;
    }

    :host([flow^="column"][gap]) ::slotted(*:first-child) { margin-top: 0; }
    :host([flow^="column"][gap]) ::slotted(*:last-child) { margin-bottom: 0; }

    :host([flow^="row"][gap]) ::slotted(*) {
      margin: 0 calc(var(--v-ui-flexbox-gap, var(--v-ui-layout-spacing)) * var(--v-ui-flexbox-gap-size));
    }

    :host([flow^="row"][gap]) ::slotted(*:first-child) { margin-left: 0; }
    :host([flow^="row"][gap]) ::slotted(*:last-child) { margin-right: 0; }

    :host([flow^="row"][flow~="wrap"][gap]) {
      margin: calc(var(--v-ui-flexbox-gap, var(--v-ui-layout-spacing)) * var(--v-ui-flexbox-gap-size) * -1);
    }

    :host([flow^="row"][flow~="wrap"][gap]) ::slotted(*:first-child),
    :host([flow^="row"][flow~="wrap"][gap]) ::slotted(*:last-child),
    :host([flow^="row"][flow~="wrap"][gap]) ::slotted(*) {
      margin: calc(var(--v-ui-flexbox-gap, var(--v-ui-layout-spacing)) * var(--v-ui-flexbox-gap-size));
    }
  `,
});
