import { html, define } from "hybrids";

export default define({
  tag: "v-ui-form-error",
  render: () => html`
    <v-ui-icon name="error" size="medium"></v-ui-icon>
    <slot></slot>
  `.css`
    :host {
      display: flex;
      align-items: center;
      color: var(--v-ui-color-alert-danger);
      background-color: rgba(254, 107, 107, 0.2);
      border: 1px solid rgba(254, 107, 107, 0.2);
      border-radius: 8px;
      padding: 8px;
      --v-ui-color-link: #FE6B6B;
      --v-ui-icon-color: var(--v-ui-color-alert-danger);
    }

    v-ui-icon {
      margin-right: 8px;
    }

    :host ::slotted(*) {
      margin: 0;
    }
  `,
});
