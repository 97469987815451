import { define, html } from "hybrids";

export interface VUiLink {
  href: string;
  target: string;
  underline: boolean;
  block: boolean;
}

export default define<VUiLink>({
  tag: "v-ui-link",
  href: "",
  target: "_self",
  underline: false,
  block: false,
  render: ({ href, target, underline }) => html`<a
    href="${href}"
    target="${target}"
    class="${{ underline }}"
  >
    <slot></slot>
  </a>`.css`
    :host {
      display: inline;
    }

    :host([block]) { display: block; }
    :host([block]) a { display: block; height: 100%; }

    a {
      display: inline;
      color: inherit;
      text-decoration: none;
      transition: opacity 200ms;
    }

    a:active {
      opacity: 0.8;
    }

    @media (hover: hover) and (pointer: fine) {
      a:hover {
        opacity: 0.8;
      }

      a:active {
        opacity: 0.6;
      }
    }

    a.underline {
      text-decoration: underline;
    }

    a:focus:not(:focus-visible) {
      outline: none;
    }
  `,
});
